<template>
  <v-list>
    <v-list-item>
      <!-- avatar -->
      <v-list-item-avatar>
        <v-icon large :color="iconColorClass">
          <slot name="icon-name">{{ iconName }}></slot>
        </v-icon>
      </v-list-item-avatar>

      <!-- title -->
      <v-list-item-content>
        <v-list-item-title :class="titleClass">
          <slot name="default">
            <span>{{ title }}</span>
          </slot>
        </v-list-item-title>
      </v-list-item-content>

      <!-- action -->
      <v-list-item-action v-show="hasSlot('icon-cross')">
        <slot name="icon-cross"></slot>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { dialogHeaderMixin } from "@/mixins/shared/base/dialog/dialogHeaderMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "BaseDialogHeader",
  // inheritAttrs: false,
  mixins: [dialogHeaderMixin, slottableMixin]
};
</script>
