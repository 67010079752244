// design
import { Color } from "@/design/colors/Color";

// vuex/store
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

/**
 * Dialog Toolbar Settings Mixin encapsulate Settings functionality of dialog Toolbar
 */
export const dialogToolbarSettingsMixin = {
  computed: {
    ...mapGetters({
      dialogToolbarStyle: "dialogToolbarStyle",
      dialogTitleColor: "dialogTitleColor"
    }),
    /**
     * Get Material Design Dialog Title Text Color
     * @returns {Color}
     */
    mdDialogTitleColor() {
      return new Color(
        this.dialogTitleColor.name,
        this.dialogTitleColor.variantType,
        this.dialogTitleColor.variantNumber
      );
    },
    /**
     * Get Dialog Title Text Color Class
     * @returns {string|*}
     */
    dialogTitleColorClass() {
      return this.mdDialogTitleColor.getClassColor();
    }
  }
};
