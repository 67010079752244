// design
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";
import { Color } from "@/design/colors/Color";

// mixins
import { dialogToolbarSettingsMixin } from "@/mixins/shared/base/settings/dialogToolbarSettingsMixin";

/**
 * Dialog Header Mixin encapsulate any dialog header logic
 */
export const dialogHeaderMixin = {
  mixins: [dialogToolbarSettingsMixin],
  props: {
    iconName: {
      type: String,
      default: ""
    },
    iconColor: {
      type: Color,
      default: undefined
    },
    title: {
      type: String,
      default: ""
    },
    titleColor: {
      type: Color,
      default: undefined
    }
  },
  computed: {
    /**
     * Get Icon Color
     * Note: if props.iconColor is not provided,
     * then use Dialog Title Color by user setting
     * @returns {Color}
     */
    computedIconColor() {
      return this.iconColor ? this.iconColor : this.mdDialogTitleColor;
    },
    /**
     * Get Title Color
     * Note: if props.titleColor is not provided,
     * then use Dialog Title Color by user setting
     * @returns {Color}
     */
    computedTitleColor() {
      return this.titleColor ? this.titleColor : this.mdDialogTitleColor;
    },
    /**
     * Get Icon Color class
     * Note: This implements Material Design Color class
     * @returns {string|*}
     */
    iconColorClass() {
      return this.iconColor
        ? this.iconColor.getClassColor()
        : this.dialogTitleColorClass;
    },
    /**
     * Get title text Class
     * @returns {string|undefined} title text Class
     */
    titleClass() {
      const color = this.titleColor ? this.titleColor : this.mdDialogTitleColor;
      return new Text(
        color,
        displayTypes.title,
        fontEmphasis.bold
      ).getClassText();
    }
  }
};
